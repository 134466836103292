.hp_navbar {
  width: 200px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  position: absolute;
  bottom: -245px;
  right: 42.5px;
  z-index: 1;
}

.hp_nav_li {
  height: 45px;
  background-color: var(--white);
}

.hp_nav_li:hover {
  background-color: var(--right-side);
}

.hp_nav_list {
  list-style-type: none;
}