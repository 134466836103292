.media_thumbnail {
  width: 150px;
  height: 150px;
  background: #d9d9d9;
  box-shadow: inset 0px -20px 20px rgba(0, 0, 0, 0.25);
  position: relative;
  cursor: pointer;
  padding: 20px;
}

.label {
  position: absolute;
  left: 3px;
  top: 6px;
}

.empty_checkbox {
  border-radius: 50%;
  border: 2px solid #9a9a9ae3;
  width: 23.25px;
  height: 23.25px;
}

.label__checkbox {
  display: none;
}

.label__check {
  position: absolute;
  top: 0px;
  border-radius: 50%;
  background: #9a9a9ae3;
  opacity: 0;
  width: 23.25px;
  height: 23.25px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.label__check i.icon {
  color: transparent;
  transition: opacity 0.3s 0.1s ease;
  -webkit-text-stroke: 1px #e3e3e3;
}

.label__check:hover {
  border: 1px solid rgba(0, 0, 0, 0.2);
  background-color: #817c7c80;
}

.label__checkbox:checked + .label__text .label__check {
  animation: check 0s cubic-bezier(0.895, 0.03, 0.685, 0.22) forwards;
}

.label__checkbox:checked + .label__text .label__check .icon {
  opacity: 1;
  transform: scale(0);
  color: white;
  -webkit-text-stroke: 0;
  -webkit-animation: icon 0s cubic-bezier(1, 0.008, 0.565, 1.65) 0s 1 forwards;
  animation: icon 0s cubic-bezier(1, 0.008, 0.565, 1.65) 0s 1 forwards;
}

.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@keyframes icon {
  from {
    opacity: 0;
    transform: scale(0.3);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes check {
  100% {
    background: #008069;
    border: 0;
    opacity: 1;
  }
}

.media_thumbnail:hover .label__check {
  opacity: 1;
}

.media_thumbnail img {
  width: 110px;
  height: 110px;
}

.media_gif {
  width: 22px;
  height: 12px;
  background: #ffffff;
  border-radius: 4px;
  position: absolute;
  left: 0.25rem;
}

.media_gif h5 {
  color: #000000;
  text-align: center;
}

.media_video {
  display: flex;
  align-items: center;
  gap: 4px;
  position: absolute;
  left: 0.25rem;
}

.media_video > * {
  color: #ffffff;
}

.fs_12 {
  font-size: 12px;
}

.media_starred {
  position: absolute;
  right: 0.25rem;
  color: var(--white);
  font-size: 14px;
  margin-top: -3px;
}

.media_details {
  font-family: "Roboto";
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
}
