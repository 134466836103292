.chat_container {
  width: 100%;
  padding-left: 72px;
  padding-right: 80px;
  flex: 1;
  overflow-x: hidden;
  overflow-y: scroll;
  height: 81vh;
  background: #efeae2;
}

.chat_container p {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  margin: 0px;
  color: rgba(0, 0, 0, 0.85);

  word-break: break-all;
  white-space: normal;
}

.chat_container a {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-decoration-line: underline;
  color: #2d9cdb;
  padding: 10px 10px 10px 14px;
}

.reduce_padding {
  padding: 0 1.5rem 0 2rem;
}

.chat_container::-webkit-scrollbar {
  width: 6px;
}

.chat_container::-webkit-scrollbar-thumb {
  background: #d9d9d9;
  border-radius: 3px;
}
