.loader {
  border: 6px solid rgba(211, 211, 211, 1);
  border-radius: 50%;
  border-top: 6px solid rgba(0, 128, 105, 1);
  width: 33.33px;
  height: 33.33px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loading p {
  font-family: "Roboto";
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}

.loading {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  gap: 22.33px;
  position: absolute;
  z-index: 3;
  top: 0;
  background-color: rgba(255, 255, 255, 0.432);
}
