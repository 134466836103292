.cta_container {
  padding: 0px 20px;
}

.cta_options {
  display: flex;
  align-items: center;
  gap: 10.31px;
  height: fit-content;
  padding: 10px;
  margin-top: 20px;
}

.cta_options img {
  width: 16px;
  height: 16px;
  cursor: pointer;
}

.cta_options_content h5 {
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 36px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
}

.slider::before {
  position: absolute;
  content: "";
  height: 17px;
  width: 17px;
  left: 0px;
  background-color: rgb(92, 152, 111);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked+.slider {
  background-color: #d0f4ee;
}

input:focus+.slider {
  box-shadow: 0 0 1px #29b865;
}

input:checked+.slider:before {
  -webkit-transform: translateX(18px);
  -ms-transform: translateX(18px);
  transform: translateX(18px);
}

.slider.round {
  border-radius: 34px;
  width: 35px;
  height: 17px;
}

.slider.round:before {
  border-radius: 50%;
}

.cta_options i {
  cursor: pointer;
  font-size: 15px;
}

.cta_descriptions {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: rgba(0, 0, 0, 0.45);
}


.cta_options_content {
  margin-right: auto;
}

.mt_10 {
  margin-top: 10px;
}

.mt_30 {
  margin-top: 30px;
}

.Cta_contact_info i {
  cursor: pointer;
  font-size: 15px;
}