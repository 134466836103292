.forward_contactList {
  height: 708px;
  padding-left: 10px;
  padding-right: 20px;
  overflow: auto;
}

.forward_contactList > p {
  color: #00a884;
  padding: 20px 0 16px 10px;
}

.forward_contactList > ul {
  list-style: none;
}

.forward_contactList > ul > li {
  position: relative;
}
