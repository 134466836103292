.link_container:hover {
  background-color: #f5f5f5;
}

.link_preview {
  display: flex;
  align-items: center;
  gap: 20px;
  padding-bottom: 10px;
  padding-left: 2.75rem;
}

.link_preview p,
h5 {
  color: #7a7a7a;
}

.link_preview p {
  margin-bottom: 7px;
}

.link_footer {
  display: flex;
  gap: 6.5px;
}

.link_footer > span {
  margin-right: auto;
}

.link_footer > *:not(span) {
  display: flex;
  align-items: flex-end;
}

.link_footer_link {
  display: block;
  max-width: 14.85rem;
  color: #2d9cdb;
}

.link_footer:last-child {
  margin-right: 3px;
}

.link_footer > i {
  color: #9d9d9d;
  font-size: 11px;
  cursor: pointer;
}

.link_footer > p {
  margin-right: 16px;
}

.link_message {
  width: 77%;
  position: relative;
  border-radius: 6px;
  padding: 6px 10px 6px 6px;
  background: #ffffff;
}

.link_message_received {
  background: #e7ffdb;
}

.link_message::after {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  left: -10px;
  top: 0px;
  border-bottom: 11px solid transparent;
  border-left: 6px solid transparent;
  border-right: 10px solid #ffffff;
  border-top: 13px solid #ffffff;
}

.link_message_received::after {
  border-right: 10px solid #e7ffdb;
  border-top: 13px solid #e7ffdb;
}

.link_message_video {
  width: 20.5rem;
  padding: 6px 0px 5px 5px;
}

.link_file {
  height: 80px;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 24px 19px 24px 0px;
  cursor: pointer;
  border-radius: 6px;
  background: #f5f6f6;
  margin-bottom: 4px;
}

.link_file_received {
  background: #d1f4cc;
}

.link_file_video {
  height: 14.375rem;
  width: max-content;
  padding: 0;
  flex-direction: column;
  padding: 4px 4px 0 4px;
  align-items: flex-start;
  gap: 7px;
}

.forward_link {
  display: flex;
  gap: 5px;
  margin-bottom: 3px;
  margin-left: 0.5em;
}

.forward_link i {
  color: #7a7a7a;
  font-size: 12px;
}

.mb_6 {
  margin-bottom: 6.4px;
}

.p_wrap {
  word-break: break-all;
  width: 15.625rem;
}

.link_truncate {
  text-decoration: underline;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.links_font_large {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
}

.link_font_small {
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
}

.link_video_play {
  width: 19.375rem;
  height: 9.5rem;
  background-color: black;
  border-radius: 6px;
}

.link_link_placeholder {
  width: 5rem;
  height: 5rem;
  background-color: #d9d9d9;
  border-radius: 6px 0px 0px 6px;
}

.center_item {
  display: flex;
  align-items: center;
  justify-content: center;
}
