.left_header {
  background: var(--chatbox-header-background);
  padding: 10px 20px 10px 20px;
  height: 82px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.left_header img {
  width: 50px;
  height: 50px;
}

.left_header .sidebar_header_features {
  display: flex;
  gap: 30px;
}

.left_header .sidebar_header_features img {
  height: 20px;
  width: 20px;
}

.left_header .sidebar_header_features i {
  color: var(--icon-color);
  font-size: 20px;
}

.sidebar_searchbox {
  flex-grow: 1;
  height: 36px;
  background: var(--chatbox-header-background);
  border-radius: 12px;
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 10px 12px 10px 12px;
}

.sidebar_searchbox_main i {
  color: var(--icon-color);
}

.filter_icon {
  font-size: 20px;
}

.sidebar_searchbox input {
  border: none;
  outline: none;
  background: var(--chatbox-header-background);
  flex-grow: 1;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */
  color: rgba(0, 0, 0, 0.45);
}

.sidebar_searchbox_main {
  display: flex;
  align-items: center;
  gap: 20px;
  /* width: 520px; */
  padding: 10px 22px 10px 10px;
  height: 56px;
  background: var(--white);
  box-shadow: var(--box-shadow);
}

.chat_box {
  width: 100%;
  height: 78vh;
  margin-top: 10px;
  overflow: auto;
  padding: 0px 20px 0px 10px;
}

.receiver_chats {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 10px 20px 0px;
  cursor: pointer;
  border-radius: 5px;
}

.receiver_chats img {
  width: 50px;
  height: 50px;

  border-radius: 50%;
}

.receiver_name {
  flex-grow: 1;
}

.receiver_name h5 {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: rgba(0, 0, 0, 0.85);
}

.display_mess_p {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: rgba(0, 0, 0, 0.45);
}

.active_mess {
  font-size: 0.85rem;
}

.display_mess .active_mess {
  color: var(--black);
}

.display_mess .active_icon {
  color: var(--black);
}

.display_mess h5 {
  color: var(--black);
}

.read_message_timestamp {
  color: rgba(0, 0, 0, 0.65);
}

.show_time h5 {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 3.91px;
}

.display_mess {
  display: flex;
  align-items: center;
  margin-top: 5px;
  gap: 6px;
}

.mess_features {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  justify-content: flex-end;
}

.thumbtack_icon {
  font-size: 11px;
  transform: rotate(26deg);
}

.show_time .chat_time_active {
  color: var(--primary-color);
  display: flex;
  justify-content: flex-end;
}

.mess_features img {
  width: 19px;
  height: 19px;
}

.mess_features h5 {
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;
  background: var(--primary-color);
  border-radius: 91px;
  padding: 1px 4px;
  max-width: 27px;
  height: 17px;
  margin: 0px;
  color: var(--white);
}

.mess_features i {
  color: #888787;
}

.chat_box::-webkit-scrollbar {
  width: 6px;
}

.chat_box::-webkit-scrollbar-thumb {
  background: #d9d9d9;
  border-radius: 3px;
}

.line {
  border: 1px solid var(--line-colour);
}

.display_mess i {
  font-size: 12px;
}

.fa-camera {
  color: var(--black);
}

.dark_double_tick {
  color: black !important;
}

.transform_pinned_icon {
  transform: matrix(-0.87, -0.5, -0.5, 0.87, 0, 0);
}

.display_switch {
  display: none;
}

article:hover .display_switch {
  display: flex;
}

.unread_message_timestamp {
  display: flex;
  justify-content: flex-end;
  color: #00a884;
}
