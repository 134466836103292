.doc_preview {
  display: flex;
  gap: 20px;
  align-items: center;
  padding-left: 11.5px;
}

.doc_preview > input[type="checkbox"] {
  width: 21px;
  height: 21px;
  accent-color: #008069;
}

.doc_preview p {
  font-weight: 400;
  color: #7a7a7a;
  font-size: 12px;
  line-height: 15px;
  margin-bottom: 7px;
}

.doc_preview h5 {
  font-weight: 400;
  color: #7a7a7a;
  font-size: 10px;
  line-height: 12px;
}

.doc_time {
  width: fit-content;
  display: flex;
  gap: 6.5px;
  margin-left: auto;
  margin-top: 7px;
}

.doc_time i {
  color: #9d9d9d;
  font-size: 11px;
  cursor: pointer;
}

.doc_message {
  width: 77%;
  position: relative;
  border-radius: 6px;
  gap: 4px;
  padding: 6px 10px 10px 6px;
  background: #ffffff;
}

.doc_message_received {
  background: #e7ffdb;
}

.doc_message::after {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  left: -10px;
  top: 0px;
  border-bottom: 11px solid transparent;
  border-left: 6px solid transparent;
  border-right: 10px solid #ffffff;
  border-top: 13px solid #ffffff;
}

.doc_message_received::after {
  border-right: 10px solid #e7ffdb;
  border-top: 13px solid #e7ffdb;
}

.doc_file {
  height: 80px;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 24px 19px 24px 10px;
  cursor: pointer;
  border-radius: 6px;
  background: #f5f6f6;
}

.doc_file > img:last-child {
  margin-left: auto;
}

.doc_file_received {
  background: #d1f4cc;
}

.line {
  border: 1px solid var(--line-colour);
  margin: 10px 0px;
}

.forward_doc {
  display: flex;
  gap: 5px;
  margin-bottom: 3px;
  margin-left: 0.5em;
}

.forward_doc i {
  color: #7a7a7a;
  font-size: 12px;
}
