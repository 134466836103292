.panel_main {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 31.5rem;
  height: 30px;
  margin-top: 92px;
}

.panel_box {
  height: 30px;
  width: 24%;
  cursor: pointer;
}

.panel_box_selected {
  border-bottom: solid 4px #008069;
}

.panel_box > h5 {
  text-align: center;
  color: #aaaaaa;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
}

.panel_box_selected > h5 {
  color: #008069;
}
