.chat_image {
  padding: 0.25rem;
  width: 100%;
  position: relative;
}

.chat_image > img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.message_body {
  display: flex;
  gap: 0.25rem;
}

.message_body > p {
  flex-grow: 1;
  padding: 10px 4px 10px 14px;
}
