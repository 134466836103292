.danger_cta {
  display: flex;
  align-items: center;
  gap: 10.31px;
}

.danger_cta > h5 {
  font-weight: 500;
  line-height: 16px;
  font-size: 1rem;
}

section .danger_cta > * {
  color: var(--red);
  cursor: pointer;
}

.danger_group_box_shdw {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.danger_styles {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 1.5rem;
  padding: 1.5rem;
}

.danger_bg_color_1 {
  background: var(--left-side);
}
