.forward_container {
  width: 520px;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.forward_header {
  height: 82px;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  gap: 13.75px;
}

.forward_header > button {
  border: none;
  cursor: pointer;
}

.forward_header > p {
  color: var(--white);
}

.forward_searchbar_container {
  height: 64px;
  padding: 10px;
}

.forward_searchbar_content {
  height: 44px;
  background-color: #e8e8e8;
  border-radius: 4px;
  display: flex;
  align-items: center;
}

.forward_left {
  height: 21px;
  width: 21px;
  margin-left: 11.75px;
}

.forward_searchbar {
  text-indent: 10px;
  background-color: inherit;
  border: none;
  border-radius: 4px;
  height: 100%;
  flex-grow: 1;
}

.forward_searchbar:focus {
  outline: none;
}

.forward_green {
  background-color: #008069;
}

.forward_font_bold {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
}

.mb_10 {
  margin-bottom: 10px;
}

.fit_parent {
  height: 100%;
  width: 100%;
}

.bg_white {
  background-color: white;
}
