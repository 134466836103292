.placeholder_container {
  height: 92vh;
  background: var(--right-side);
  z-index: 1;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
}

.placeholder_container .placeholder_main h5 {
  font-weight: 400;
  font-size: 32px;
  line-height: 39px;
  color: rgba(0, 0, 0, 0.45);
  text-align: center;
  margin-top: 33px;
}

.placeholder_container .placeholder_main p {
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  color: rgba(0, 0, 0, 0.45);
  margin-top: 17.5px;
}

.placeholder_container .end-to-end {
  position: absolute;
  bottom: 24px;
  color: var(--secondary-lighter);
  font-size: 14px;
  line-height: 20px;
  display: flex;
  width: 41.4%;
  justify-content: center;
  gap: 11px;
  align-items: center;
}

.placeholder_container .end-to-end h5 {
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  color: rgba(0, 0, 0, 0.45);
}

.end-to-end i {
  color: var(--black);
}

.placeholder_container .placeholder_main {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 41.4%;
}