.forward_contact_container {
  height: 80px;
  display: flex;
  align-items: center;
  gap: 16px;
}

.forward_contact_container::before {
  content: "";
  position: absolute;
  left: 40px;
  bottom: 0;
  height: 1px;
  width: 450px;
  border-bottom: 1px solid #dedede;
}

.contact_chechbox {
  height: 21px;
  width: 21px;
  accent-color: #008069;
  outline: 1px solid #4b4b4b;
}

.contact_content_container {
  padding-top: 10px;
  padding-bottom: 20px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.contact_img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.contact_content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 3px;
}

.contact_name {
  color: rgba(0, 0, 0, 0.85);
}

.contact_status {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: rgba(0, 0, 0, 0.45);
}
