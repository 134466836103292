.details_container {
  width: 480px;
  padding: 40px 10px;
}

.details_img {
  width: 201px;
  height: 201px;
  background-color: rgba(112, 127, 135, 1);
  border-radius: 50%;
  align-items: center;
}

.img {
  height: 120px;
  width: 120px;
}

.cam_text {
  width: 6rem;
  color: #fff;
  text-align: center;
}

.details-popup_container {
  position: absolute;
  top: 7.5rem;
  left: 20.5rem;
  z-index: 10;
  width: 10rem;
  height: 2.5rem;
}

.details_subject > input {
  border: none;
  flex-grow: 1;
}

.details_line {
  background-color: rgba(169, 169, 169, 1);
  height: 1px;
}

.details_settings_container {
  width: 480px;
  padding: 10px 10px 40px 10px;
}

.details_picker {
  bottom: 205px;
  left: 130px;
}

.details-create_container {
  padding: 50px 0px;
  margin-top: 10px;
  
  height: 150px;
}

.create_btn {
  border: none;
  background: none;
}

.space_between {
  display: flex;
  justify-content: space-between;
}

.justify_center {
  display: flex;
  justify-content: center;
}

.details_font_light {
  font-size: 14px;
  color: #7a7a7a;
}

.mb_14 {
  margin-bottom: 14px;
}

.box_shadow {
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.absolute {
  position: absolute;
}

.top_200 {
  top: 200px;
}

.top_240 {
  top: 240px;
}

.cursor_pointer {
  cursor: pointer;
}

.setting_name > p {
  color: #444;
}

.fs_14 {
  font-size: 14px;
}

.fit_content {
  border-radius: 50%;

  object-fit: cover;
}
