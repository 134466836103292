.link_header {
  width: 100%;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.link_header > * {
  display: flex;
  gap: 1rem;
}

.link_header > figure > figcaption {
  color: #2c2c2c;
}

.link_header > figure > img {
  height: 26px;
  width: 26px;
}

.link_header_right > h5 {
  color: rgba(64, 64, 64, 0.85);
}

.link_header_font {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}
