.preview_container {
  width: 99%;
  height: 210px;

  padding: 0.675rem;

  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
}

.preview_header {
  display: flex;
  align-items: center;
  gap: 1.25rem;
}

.preview_header > h4,
.preview_header > h5 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
}

.preview_header > h5 {
  flex-grow: 1;

  color: rgba(170, 170, 170, 0.85);
  text-decoration: none;
}

.preview_header > h4 {
  color: rgba(170, 170, 170, 0.85);

  opacity: 0.8;
}

.preview_img {
  display: flex;
  gap: 10px;
  justify-content: space-between;
  margin-top: 24px;
}

.cursor_pointer {
  cursor: pointer;
}
