.user_container {
  width: 99%;
  overflow: auto;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 28px;
  margin-top: 82px;
}

.user_profile {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 20px 0px;
  background: var(--msg-background);
}

.user_profile h5 {
  font-size: 22px;
  color: rgba(0, 0, 0, 0.85);
}

.user_profile p {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
}

.user_profile img {
  width: 220px;
  height: 220px;
  cursor: pointer;
}

.user_caption {
  width: 100%;
  padding: 10px 20px 10px;
  background: var(--msg-background);
}

.user_caption h5 {
  font-size: 16px;
  color: rgba(170, 170, 170, 0.85);
}

.user_caption p {
  font-weight: 500;
  line-height: 24px;
  font-size: 20px;
  color: rgba(0, 0, 0, 0.85);
  margin-top: 16px;
}

.user_styles {
  font-style: normal;
  font-weight: 500;
  line-height: 16px;

  background-color: #fff;
}

.user_styles:last-child {
  margin-bottom: 28px;
}
