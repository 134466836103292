.media_container {
  width: 100%;
  height: 13rem;
  background: #ffffff;
  padding: 10px;
  margin-bottom: 1.5rem;
}

.media_date h5 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1rem;
  color: rgba(170, 170, 170, 0.85);

  margin-bottom: 1rem;
}

.media_imgs {
  display: flex;
  gap: 1rem;
}

.scroll ::-webkit-scrollbar {
  width: 6px;
}

.scroll ::-webkit-scrollbar-thumb {
  background: #d9d9d9;
  border-radius: 3px;
}
