#hp_button {
  width: 100%;
  color: #484848;
  font-size: 14px;
  text-align: left;
  padding: 14px 10px;
  border: none;
  background-color: inherit;
  cursor: pointer;
}

#hp_button:focus {
  outline: none;
}
