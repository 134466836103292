.forward_selected_contacts {
  color: white;
  color: rgba(255, 255, 255, 0.85);
  width: 520px;
  height: 66px;
  padding: 25px 20px;
  position: absolute;
  bottom: 0;
  left: 0;
}

.forward_selected_btn {
  background-color: #239079;
  border: none;
  height: 60px;
  width: 60px;
  border-radius: 50%;
  position: absolute;
  top: -30px;
  right: 35px;
  cursor: pointer;
}
