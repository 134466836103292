.chip_container {
  height: 26px;
  width: max-content;

  margin-top: 20px;
  padding: 3px 4px;

  border-radius: 16px;

  background-color: rgba(0, 0, 0, 0.08);

  display: flex;
  align-items: center;
}

.chip_img {
  width: 20px;
  height: 20px;

  border-radius: 50%;

  background-color: rgba(217, 217, 217, 1);
}

.chip_name {
  padding: 0px 6px;

  max-width: 70px;
  height: 18px;

  color: rgba(0, 0, 0, 0.87);
  font-size: 13px;
  font-family: "Roboto";
  line-height: 18px;
  letter-spacing: 0.16px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.chip_cancel {
  width: 16px;
  height: 16px;

  cursor: pointer;
}

.fit_content {
  height: 100%;
  width: 100%;
}
