 .msg_insert {
     gap: 11.56px;
     display: flex;
     justify-content: space-between;
     align-items: center;
     padding: 10px 10px 10px 10px;
     height: 60px;
     background: var(--left-header-background);
     position: relative;
 }

 .msg_insert i {
     color: #242628;
     font-size: 20px;
 }

 .msg_insert input {
     border: none;
     height: 40px;
     background: #ffffff;
     border-radius: 10px;
     padding: 12px 0px 12px 14px;
     flex-grow: 1;
 }

 .emoji_position {
     position: absolute;
     bottom: 100%;
 }

 .file_attach {
     display: flex;
     gap: 11.56px;
 }

 input:focus {
     outline: none;
 }