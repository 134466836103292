#backdrop {
  width: 100vw;
  height: 100vh;

  backdrop-filter: blur(0.5rem);

  position: absolute;
  top: 0;
  left: 0;

  z-index: 100;

  display: flex;
  justify-content: center;
  align-items: center;

  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
}
