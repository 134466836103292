.screen_container {
  position: relative;
}

.login_main {
  background: #f5f5f5;
  z-index: 100;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: #fffdfd;
  position: relative;
}

.login_main_header {
  height: 250px;
  width: 100%;
  background: #00a884;
  position: fixed;
  top: 0;
  z-index: -1;
  content: "";
}

.login_whatsapp_box {
  width: calc(100% - 38px);
  max-width: 1165px;
  margin: auto;
  height: calc(100%-38px);
  margin-top: 25px;
  overflow: hidden;
}

.login_main_container {
  height: 67.5vh;
  width: 100%;
  padding-top: 70px;
  margin-top: 62px;
  background: #f4f4f4;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login_whatsapp_box h3 {
  font-weight: 700;
  font-size: 30px;
  line-height: 36px;
  color: var(--white);
  margin-top: 52px;
}

.login_box {
  height: auto;
  padding: 0px 0px 30px 0px;
  width: 34.4%;
  background: #ffffff;
  border: 1px solid #eeeeee;
  border-radius: 10px;
}

.login_box h4 {
  font-family: "Roboto";
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #1d1b20;
  margin-top: 30px;
}

.inputs {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: 50px;
  border-radius: 4px;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #666566;
  padding: 0px 20px;
}

.invalidInputs {
  border: solid 1px var(--red);
}

.invalidInputs:focus {
  outline: 1px solid var(--red);
}



#login {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  gap: 10px;
  height: 37px;
  border: none;
  background: #008069;
  border-radius: 5px;
  width: 100%;
  margin-top: 20px;
  cursor: pointer;
}

.login_details button {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: var(--white);
}

.disable-button {
  cursor: not-allowed;
}

.login_details {
  margin-top: 50px;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.error_text {
  font-family: "Roboto";
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: var(--red);
  padding: 0px 0px 0px 20px;
  color: var(--red);
  margin-bottom: 1.25rem;
}

.blur {
  filter: blur(5px);
  z-index: 1;
}
