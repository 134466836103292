.inner_container {
  display: flex;
  gap: 0.6rem;
  margin-top: 0.75rem;
  width: 100%;
}

.inner_container > img {
  width: 2.25rem;
  height: 2.25rem;
  margin-top: 0.4rem;
  border-radius: 100%;
}

.chat_bubble {
  border-radius: 10px;
}

.send_msg .chat_bubble {
  background-color: var(--user-msg);
}

.received_msg .chat_bubble {
  background-color: var(--white);
}

.chat_msg {
  width: 60%;
  display: flex;
  position: relative;
  margin: 0.25rem 0;
}

.received_msg {
  border-radius: 0px 10px 10px 10px;
}

.send_msg {
  border-radius: 10px 0px 10px 10px;
  justify-content: flex-end;
  margin-left: auto;
}

#received::after {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 12px solid transparent;
  border-right: 10px solid #ffffff;
  border-top: 13px solid #ffffff;
  border-bottom: 11px solid transparent;
  left: -10px;
  top: 0px;
}

#sent::after {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 13px solid #e7ffdb;
  border-right: 17px solid transparent;
  border-top: 13px solid #e7ffdb;
  border-bottom: 5px solid transparent;
  right: -16px;
  top: 0px;
}

.chatterName {
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: 0em;
  padding-left: 0.75rem;
  margin-top: 0.2rem;
  margin-bottom: -7px;
}

.black_double_check {
  color: var(--black);
}

.chat_date {
  background-color: var(--white);
  width: fit-content;
  margin: auto;
  margin-top: 10px;
  box-shadow: var(--box-shadow);
  border-radius: 4px;
}

.chat_date > h5 {
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: rgba(0, 0, 0, 0.45);
  padding: 10px 14px;
}

.other_mess_img {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  width: 100%;
  gap: 4px;
}

.forward_img {
  width: 26.4%;
  height: 254px;
  background: var(--white);
  padding: 6px 6px 6px 6px;
  border-radius: 10px;
}

.forward_img .forward_icon {
  width: 30px;
  height: 30px;
  background: rgba(0, 0, 0, 0.1);
}

.right_header > img {
  border-radius: 100%;
}

.black_double_check {
  color: black;
}

.user_chat_time {
  gap: 4px;
  display: flex;
  align-items: flex-end;
  margin-right: 5px;
}

.user_chat_time > h5 {
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  text-align: right;
  color: #7a7a7a;
  display: flex;
  width: max-content;
}

.chat_time_img {
  position: absolute;
  bottom: 1rem;
  right: 0.5rem;
  gap: 4px;
}

.chat_time_img > h5 {
  color: var(--white);
  width: max-content;
}

.user_chat_time > i {
  font-size: 12px;
}
