* {
  margin: 0%;
  padding: 0%;
  box-sizing: border-box;
  font-family: "Inter";
  font-style: normal;
}

:root {
  --primary-color: #00a884;
  --chatbox-header-background: #f0f2f5;
  --box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  --red: red;
  --main-container: #f4f4f4;
  --right-side: #f0f2f5;
  --left-side: #fcfbff;
  --left-width: 31%;
  --right-width: 69%;
  --line-colour: #dedede;
  --white: #ffffff;
  --user-msg: #e7ffdb;
  --menu-header-background: #eae9e9;
  --third-section-width: 31%;
  --black: #000000;
}

.main {
  background: var(--white);
  position: relative;
  z-index: 100;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.main_header {
  height: 112px;
  width: 100%;
  background: var(--primary-color);
  position: fixed;
  top: 0;
  z-index: -1;
}

.whatsapp_box {
  width: calc(100% - 38px);
  max-width: 1684px;
  margin: auto;
  position: relative;
  margin-top: 30px;
  overflow: hidden;
}

.main_container {
  width: 100%;
  height: 100%;
  display: flex;
  text-align: inherit;
}

.left_side {
  width: var(--left-width);
  height: auto;
  background-color: var(--left-side);
}

.right_side {
  flex-grow: 1;
  height: 100%;
  background: var(--right-side);
}

.third_section {
  min-width: var(--third-section-width);
  height: 95.6vh;
  background: var(--left-side);
  overflow-y: scroll;
}

.third_section::-webkit-scrollbar {
  width: 6px;
}

.third_section::-webkit-scrollbar-thumb {
  background: #d9d9d9;
  border-radius: 3px;

  border-top: rgba(256, 256, 256, 1) 82px solid;
}

.cursor_pointer {
  cursor: pointer;
}

.blue_tick {
  color: #1e5ecc;
  font-size: 0.75rem;
}
