.mh_container {
  padding: 30.5px 22.5px;
  background-color: #008069;
  height: 82px;
  width: 31rem;
  position: fixed;
  z-index: 10;
}

.mh_icons {
  display: flex;
  justify-content: space-between;
}

.mh_icons > button {
  height: 21px;
  width: 20px;
}

.mh_icons > button,
.mh_icons_right > button {
  background-color: #008069;
  border: none;
  cursor: pointer;
}

.mh_icons_right {
  display: flex;
  gap: 32px;
}

.fit_content {
  height: 100%;
  width: 100%;
}
