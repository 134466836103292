.info_container {
  width: 31rem;
  height: 82px;

  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 10px 20px;

  background-color: var(--chatbox-header-background);

  position: fixed;
  
}

.info_container > h5 {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;

  color: rgba(0, 0, 0, 0.85);
}

.info_container > img {
  height: 16.5px;
  width: 16.5px;

  color: #4b4b4b;

  cursor: pointer;
}
