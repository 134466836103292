.su_container {
  width: 522.5px;

  padding: 30px 0px;

  background-color: #f0f2f5;

  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  left: 0px;
  bottom: 7.9rem;

  z-index: 10;
}

.select_btn {
  height: 50px;
  width: 50px;

  border-radius: 50%;

  cursor: pointer;
}

.fit_content {
  height: 100%;
  width: 100%;
}
