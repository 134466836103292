.ng_container {
  width: 520px;
  height: 1016px;

  display: flex;
  flex-direction: column;
  gap: 10px;
}

.ng_header {
  height: 82px;
  padding: 30.75px 21.5px;
  display: flex;
  align-items: center;
  gap: 11.5px;
  color: #ffffff;
  background-color: #008069;
}

.ng_header > button {
  height: 20.5px;
  width: 21px;
  background: none;
  border: none;
  cursor: pointer;
}

.ng_main {
  background-color: #fcfbff;
  padding: 10px 20px 0 10px;
}

.ng_search {
  height: 46px;
  padding-right: 10px;
}

.ng_search > input[type="text"] {
  border: none;
  background-color: #fcfbff;
}

.ng_search > input[type="text"]::placeholder {
  color: #616060;
}

.ng_contact_list {
  padding: 0px 10px;
  list-style: none;
}

.ng_contact {
  display: flex;
  align-items: center;
  gap: 10px;
  padding-top: 26px;
  padding-bottom: 20px;
}

.ng_contact_img {
  height: 50px;
  width: 50px;
}

.ng_pills {
  width: 300px;

  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.ng_line {
  height: 1px;
  background-color: #dedede;
}

.fw_500 {
  font-weight: 500;
}

.fw_400 {
  font-weight: 400;
}

.ng_font_common {
  font-size: 14px;
  line-height: 16px;
}

.ng_font_light {
  color: rgba(0, 0, 0, 0.85);
}

.pt_2 {
  padding-top: 2px;
}

.pt_16 {
  padding-top: 16px;
}

.ng_font_lighter {
  color: rgba(0, 0, 0, 0.45);
}
