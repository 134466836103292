 .right_header {
     height: 82px;
     width: 100%;
     background: var(--chatbox-header-background);
     display: flex;
     justify-content: space-around;
     align-items: center;
     padding: 10px 20px;
     gap: 10px;
     box-shadow: var(--box-shadow);
 }

 .right_header h5 {
     font-weight: 500;
     font-size: 16px;
     line-height: 16px;
     text-decoration: none;
     margin: 0px 0px 4px 0px;
     color: rgba(0, 0, 0, 0.85);
 }

 .right_header p {
     font-weight: 400;
     font-size: 14px;
     line-height: 16px;
     margin-top: 4px;
     /* color: var(--primary-color); */
     color: rgba(0, 0, 0, 0.45);
     margin: 0px;
 }

 .right_header .name_status {
     width: 81%;
     padding: 23px 0px 23px 0px;
 }

 .right_header img {
     width: 50px;
     height: 50px;
 }

 .truncate {
     width: 100%;
     white-space: nowrap;
     overflow: hidden;
     text-overflow: ellipsis;
 }

 .online_color {
     color: #27ae60 !important;
 }

 .search_bar {
     display: flex;
     gap: 38px;
 }

 .search_bar i {
     color: var(--black);
     font-size: 24px;
     cursor: pointer;
 }